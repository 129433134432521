import React, { useContext, useEffect, useState } from 'react'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Chip,
  Tabs,
  Grid,
  Typography,
  IconButton,
  Tab,
  Skeleton,
} from '@mui/material'
import { Box } from '@mui/system'
import { OpenModalProps } from 'src/@types/modalProps'
import Page from 'src/components/Page'
import Scrollbar from 'src/components/Scrollbar'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import AddEndletFilter from './AddEndletFilter'
import CloseIcon from '@mui/icons-material/Close'
import ConfigureEndletModal from './ConfigureEndletModal'
import useTabs from '../../../hooks/useTabs'
import useAuth from 'src/hooks/useAuth'
import { post, put, get } from 'src/utils/httpMethods'
import { TenantsContext } from 'src/contexts/TenantsContext'

interface Props {
  openProps: OpenModalProps
  endlet: any
  index: number
}
function SelectMeshEndletModal({ openProps, endlet, index }: Props) {
  const [openConfigureEndlet, setOpenConfigureEndlet] = useState(false)
  const { open, setOpen } = openProps
  const [loading, setLoading] = useState(false)
  const [allEp, setAllEp] = useState<any>([])
  const { selectedTenant } = useContext(TenantsContext)!
  const [freeEndlets, setFreeEndlets] = useState<any>([])
  const [openAddModal, setOpenAddModal] = useState(false)
  const [filterItems, setFilterItems] = useState<string[]>([])
  const [endlets, setEndlets] = useState<any>([])
  const [currentEp, setCurrentEp] = useState<any>({})
  const TAB_OPTIONS = ['Configure', 'Add New']
  const [currentTab, setCurrentTab] = useState('Configure')
  const { snackbarProps } = useContext(ToplevelSnackbarContext)!
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs('Configure')
  const { user } = useAuth()
  // useEffect(() => {
  //   console.warn(endlet.services[index].associatedEndlet)
  //   const EPs = endlet?.services[index]?.associatedEndlet.filter(
  //     (assEp: any) => assEp.endletId !== endlet.endletId
  //   )
  //   setEndlets(EPs)
  // }, [snackbarProps, filterItems, loading])

  const handleClose = () => {
    setOpen(false)
  }
  const handleDelete = (index: number) => {
    setFilterItems(filterItems.filter((items: any, i: number) => i !== index))
  }

  useEffect(() => {
    // const currentEp = allEp.find((ep: any) => ep.endletId === endlet.endletId)
    // console.log(currentEp?.services[index]?.associatedEndlets)
    // setEndlets(
    //   currentEp?.services[index]?.associatedEndlets.filter(
    //     (ep: any) => ep.endletId !== endlet.endletId
    //   )
    // )
    get(
      `${process.env.REACT_APP_HOST_API_URL}/endlets/services/details/${endlet.endletId}`,
      {}
    ).then((response: any) => {
      console.log(response)
      setEndlets(
        response.services[index]?.associatedEndlets.filter(
          (ep: any) => ep.endletId !== endlet.endletId
        )
      )
    })
  }, [loading, allEp, snackbarProps])

  useEffect(() => {
    getFreeEndlets()
  }, [snackbarProps, filterItems])

  const Packets_Employee = ['Packets_Engineer', 'Packets_Finance', 'Packets_Support']
  const Reseller_Employee = [
    'Reseller_Engineer',
    'Reseller_Support',
    'Reseller_Finance',
  ]

  const getFreeEndlets = () => {
    const data = filterItems.length ? { filter: [...filterItems] } : {}
    if (
      user?.role === 'Packets_Super_User' ||
      Packets_Employee.includes(user?.role) ||
      user?.role === 'Reseller_Admin' ||
      Reseller_Employee.includes(user?.role)
    ) {
      post(
        selectedTenant
          ? `${process.env.REACT_APP_HOST_API_URL}/endlets/organization/${selectedTenant}`
          : `${process.env.REACT_APP_HOST_API_URL}/endlets/filters`,

        data
      )
        .then((response: any) => {
          console.log(response)
          setAllEp(response.data)
          setFreeEndlets(
            response.data.filter(
              (res: any) => res.services.length === 0 && res.status !== 'Saved'
            )
          )
        })
        .catch((e) => {})
    } else {
      post(`${process.env.REACT_APP_HOST_API_URL}/endlets/filters`, data)
        .then((response: any) => {
          console.log(response)

          setAllEp(response.data)
          setFreeEndlets(
            response.data.filter(
              (res: any) => res.services.length === 0 && res.status !== 'Saved'
            )
          )
        })
        .catch((e: any) => {})
    }
  }
  const handleAddEndlet = (ep: any) => {
    setLoading(true)
    const currentActiveService = endlet.services[index]
    // const updatedEp = allEp.find((ep: any) => ep.endletId === endlet.endletId)

    // console.log('--------------updated-------', updatedEp)
    console.log('--------------updated-------', endlet)
    const data = {
      service: {
        associatedEndlets: [
          ...endlet.services[index].associatedEndlets,
          {
            endletId: ep.endletId,
            endletName: ep.endletName,
            endletUniqueId: ep.endletUniqueId,
          },
        ],
        // status: 'Idle',
      },
    }
    put(
      `${process.env.REACT_APP_HOST_API_URL}/services/${currentActiveService.id}`,
      data
    )
      .then((response: any) => {
        updateEpWithNewService(ep)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }
  //update selected endlet with current selected service
  const updateEpWithNewService = (ep: any) => {
    const newService = {
      endlet: {
        services: [
          {
            id: endlet.services[index].id,
            name: endlet.services[index].name,
          },
        ],
        serviceType: ep.serviceType?.includes(endlet.services[index].type)
          ? [...ep.serviceType]
          : [...ep.serviceType, endlet.services[index].type],
      },
    }

    put(`${process.env.REACT_APP_HOST_API_URL}/endlets/${ep.endletId}`, newService)
      .then((response: any) => {
        console.log(response)
        setSnackbarProps({
          open: true,
          message: 'Endlet Added Successfully!',
          severity: 'success',
        })
        getFreeEndlets()
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setSnackbarProps({
          open: true,
          message: e.message,
          severity: 'error',
        })
        setLoading(false)
      })
  }
  console.log(freeEndlets)
  console.log('endlets', endlets)
  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" fullWidth>
      <Page title="Select Endlet">
        <Stack direction={'row'} justifyContent="space-between">
          <DialogTitle>Select Endlet</DialogTitle>
          <IconButton sx={{ mr: 2, mt: 3 }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Grid
          spacing={1}
          container
          alignItems="center"
          direction={'row'}
          sx={{ m: 2, p: 0, width: 'auto' }}
        >
          <Grid item>
            <Box>
              <Button
                onClick={() => setOpenAddModal(true)}
                variant="outlined"
                startIcon={<FilterAltIcon />}
              >
                Add Filter
              </Button>

              <AddEndletFilter
                openProps={{ open: openAddModal, setOpen: setOpenAddModal }}
                filterProps={{
                  setFilterItems: setFilterItems,
                  filterItems: filterItems,
                }}
              />
            </Box>
          </Grid>

          {/* <Stack direction="row" spacing={1} sx={{}}> */}
          {filterItems.map((items: any, index: number) => {
            return (
              <Grid item key={index}>
                <Chip
                  label={
                    items.label
                      .split('_')
                      .map(
                        (each: any) => each.charAt(0).toUpperCase() + each.slice(1)
                      )
                      .join(' ') +
                    ' ' +
                    items.operator.split('_').join(' ') +
                    ' ' +
                    items.value
                  }
                  style={{ padding: '20px 0px 20px 0px', fontSize: '15px' }}
                  onDelete={() => handleDelete(index)}
                />
              </Grid>
            )
          })}
          {/* </Stack> */}
        </Grid>

        <DialogContent dividers={true}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 1, bgcolor: 'background.neutral' }}
          >
            {TAB_OPTIONS.map((tab) => (
              <Tab
                disableRipple
                key={tab}
                label={tab}
                value={tab}
                onClick={() => setCurrentTab(tab)}
              />
            ))}
          </Tabs>
          <Scrollbar sx={{ height: '50vh' }}>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <List component="nav" aria-label="main mailbox folders">
                {currentTab === 'Configure' ? (
                  endlets?.length ? (
                    <>
                      {endlets?.map((ep: any, i: any) => (
                        <Stack direction="row" key={i}>
                          <ListItemButton
                            key={ep.endletId}
                            sx={{ cursor: 'default' }}
                          >
                            <ListItemText primary={ep.endletName} />
                            <Box
                              sx={{ ml: 'auto', py: 1 }}
                              onClick={() => setCurrentEp(ep)}
                            >
                              <Typography
                                onClick={() => setOpenConfigureEndlet(true)}
                                variant="body2"
                                component={'span'}
                                sx={{
                                  px: 2,
                                  pb: 2,
                                  cursor: 'pointer',
                                  color: 'blue',
                                }}
                              >
                                <u>Configure</u>
                              </Typography>
                            </Box>
                          </ListItemButton>
                        </Stack>
                      ))}
                    </>
                  ) : (
                    <>
                      <Typography variant="body1" sx={{ pl: 1, mt: 2 }}>
                        No Data Found
                      </Typography>
                    </>
                  )
                ) : freeEndlets.length ? (
                  <>
                    {loading ? (
                      <Box sx={{ m: 2 }}>
                        <Skeleton animation="wave" height={60} />
                        <Skeleton animation="wave" height={60} />
                        <Skeleton animation="wave" height={60} />
                      </Box>
                    ) : (
                      freeEndlets?.map((ep: any, i: any) => (
                        <Stack direction="row" key={i}>
                          <ListItemButton
                            key={ep.endletId}
                            sx={{ cursor: 'default' }}
                          >
                            <ListItemText primary={ep.endletName} />
                            <Box
                              sx={{ ml: 'auto', py: 1 }}
                              onClick={() => setCurrentEp(ep)}
                            >
                              <Typography
                                onClick={() => handleAddEndlet(ep)}
                                variant="body2"
                                component={'span'}
                                sx={{
                                  px: 2,
                                  pb: 2,
                                  cursor: 'pointer',
                                  color: 'blue',
                                }}
                              >
                                <u>Add Endlet</u>
                              </Typography>
                            </Box>
                          </ListItemButton>
                        </Stack>
                      ))
                    )}
                  </>
                ) : (
                  <>
                    <Typography variant="body1" sx={{ pl: 1, mt: 2 }}>
                      No Data Found
                    </Typography>
                  </>
                )}
              </List>
              <Divider />
            </Box>
          </Scrollbar>
        </DialogContent>
      </Page>
      <ConfigureEndletModal
        openProps={{
          open: openConfigureEndlet,
          setOpen: setOpenConfigureEndlet,
        }}
        endletName={currentEp.endletName}
        id={currentEp.endletId}
        setIsIPAdded={() => {
          return false
        }}
        serviceId={endlet.services[index].id}
      />
    </Dialog>
  )
}

export default SelectMeshEndletModal

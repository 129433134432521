import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
//@mui
import {
  Box,
  Card,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Step,
  Stepper,
  StepLabel,
  useTheme,
  Skeleton,
  TextField,
  Button,
} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
//custom
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { get, post, put } from 'src/utils/httpMethods'
import { useNavigate, useParams, useLocation } from 'react-router'
import useTabs from '../../../hooks/useTabs'
import { Stack } from '@mui/system'
import { makeStyles } from '@mui/styles'
import Label from 'src/components/Label'
import EpDtConfiguration from './EpDtConfiguration'
import EpDtServices from './EpDtServices'
import DeleteEPModal from './DeleteEPModal'
import { LoadingButton } from '@mui/lab'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { ReactComponent as ServiceIcon } from '../../../assets/services-icon.svg'
import { SvgIcon } from '@mui/material'
import { ToplevelSnackbarContext } from 'src/contexts/SnackbarContext'
import { EndletInterfaces } from './ConfigureEndletModal'
import Observability from '../Observability'
import { PATH_SERVICES } from 'src/routes/paths'
import Page from 'src/components/Page'

////////////////////// Endlet Context //////////////////////////
export const EndletContext = createContext<{
  endlet: any
  endletId: string
  getEndlets: () => Promise<void>
  associatedEndlets: any
  refetchInterfaces: (endletId: string) => void
} | null>(null)

export const InterfacesContext = createContext<EndletInterfaces[] | null>(null)

function EndletDetails() {
  const theme = useTheme()
  const { setSnackbarProps } = useContext(ToplevelSnackbarContext)!
  const { pathname } = useLocation()
  const [contentLoading, setContentLoading] = useState(true)
  const [openDeleteEP, setOpenDeleteEP] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const { id } = useParams()
  const [endlet, setEndlet] = useState<any>({})
  const activeTab = pathname.split('/').slice(-1)
  const [currentState, setCurrentState] = useState<any>({})
  const [endletName, setEndletName] = useState(endlet?.endletName)
  const { currentTab, onChangeTab, setCurrentTab } = useTabs(activeTab[0])
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isErrorInOsStep, setIsErrorInOsStep] = useState<boolean>(false)

  const inputRef = useRef<any>(null)!
  const STATUS_OPTIONS = [
    'Endlet Configured',
    'SSH Key Validated',
    'OS Validated',
    'Endlet Deployed',
  ]

  const osStages = ['OS Validated', 'Interface Retrieved', 'Secrets Validated']

  useEffect(() => {
    setCurrentTab(activeTab[0])
  }, [activeTab, setCurrentTab])

  const navigate = useNavigate()

  //fetch interface details to display as accordion
  const [interfaces, setInterfaces] = useState<EndletInterfaces[] | null>(null)

  const getInterfaceDetails = (id: string) => {
    get(`${process.env.REACT_APP_HOST_API_URL}/interfaces/endlet/${id}`)
      .then((res) => {
        setInterfaces(res.data)
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    getInterfaceDetails(id ?? '')
  }, [])

  const getEndlets = useCallback(async () => {
    setContentLoading(true)
    get(`${process.env.REACT_APP_HOST_API_URL}/endlets/${id}`, {})
      .then((response: any) => {
        setEndlet(response)

        setCurrentState(
          response.currentProgress.find(
            (status: any) => status.deploymentStatus === response.deploymentStatus
          )
        )

        setEndletName(endlet.endletName)

        setIsErrorInOsStep(
          response.currentProgress
            .filter((x: any) => osStages.includes(x.deploymentStatus))
            .filter((y: any) => y.issue.isResolved === true).length <
            response?.currentProgress?.filter((x: any) =>
              osStages.includes(x.deploymentStatus)
            ).length
        )
        setContentLoading(false)
      })
      .catch((e) => {
        setContentLoading(false)
      })
  }, [])

  const TAB_OPTIONS = [
    {
      value: 'configuration',
      icon: <SettingsSuggestIcon width={20} height={20} />,
      component: (
        <EpDtConfiguration
          endlet={endlet}
          endletName={endletName}
          getEndlets={getEndlets}
        />
      ),
      label: 'Configuration',
    },

    {
      value: 'observability',
      icon: <VisibilityIcon width={20} height={20} />,
      component: <Observability endlet={endlet} />,
      label: 'Observability',
    },

    {
      value: 'services',
      icon: (
        <SvgIcon>
          <ServiceIcon />
        </SvgIcon>
      ),
      component: <EpDtServices />,
      label: 'Services',
    },
  ]
  useEffect(() => {
    getEndlets()
  }, [])

  const handleChangeElName = (e: any) => {
    if (!isEdit) {
      setIsEdit(true)
    } else {
      const data = {
        endlet: {
          endletName: endletName,
        },
      }
      setLoading(true)
      put(`${process.env.REACT_APP_HOST_API_URL}/endlets/${id}`, data)
        .then((response: any) => {
          setLoading(false)
          setSnackbarProps({
            open: true,
            severity: 'success',
            message: `Endlet Name is updated successfully`,
          })
          getEndlets()
          setIsEdit(false)
        })
        .catch((e) => {
          setLoading(false)
        })
    }
  }

  const handleValidateSSH = () => {
    setIsLoading(true)
    const data = {
      endletIp: endlet.endletIp,
      endletId: endlet.endletId,
      sshKey: {
        sshKeyName: endlet.sshKey.sshKeyName,
        username: endlet.sshKey.username,
        sshPortNumber: endlet.sshKey.sshPortNumber,
      },
    }
    post(`${process.env.REACT_APP_HOST_API_URL}/execute-workflow`, data)
      .then((response: any) => {
        setLoading(false)
        setSnackbarProps({
          open: true,
          severity: 'success',
          message: `Validation Started`,
        })

        // getEndlets()
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        console.log(e)
      })
  }

  const useStyles: any = makeStyles(() => ({
    root: {
      '& .MuiStepIcon-root.Mui-active': { color: 'orange' },
      '& .MuiStepIcon-root.Mui-completed': { color: 'green' },
      '& .MuiStepIcon-root.Mui-error': { color: 'red' },
    },
  }))

  const useStylesIcon: any = makeStyles(() => ({
    root: {
      '& .MuiStepIcon-root.Mui-active': { color: 'orange' },
    },
  }))
  const c = useStyles()
  const d = useStylesIcon()

  const associatedEndlet = endlet
    ? endlet.services?.length
      ? endlet.services[0]?.associatedEndlets
      : ''
    : ''

  useEffect(() => {
    setEndletName(endlet.endletName)
    if (isEdit) {
      inputRef.current.focus()
    }
  }, [isEdit])

  return (
    <EndletContext.Provider
      value={{
        endlet: endlet,
        endletId: id!,
        getEndlets: getEndlets,
        associatedEndlets: associatedEndlet,
        refetchInterfaces: getInterfaceDetails,
      }}
    >
      <InterfacesContext.Provider value={interfaces}>
        <Page title="Endlet View">
          <Stack direction="row" sx={{ m: 2, justifyContent: 'space-between' }}>
            <Box
              sx={{
                display: 'inline-isErrorInOsStep',
                alignItems: 'center',
                marginTop: 'inherit',
              }}
            >
              <TextField
                inputRef={inputRef}
                size="small"
                type="text"
                sx={{ mx: 1 }}
                value={endletName}
                onChange={(e) => setEndletName(e.target.value)}
                id="unique_id"
                hidden={!isEdit}
                onBlur={() => setIsEdit(false)}
              />
              <Typography
                variant="h6"
                component={'span'}
                sx={{
                  m: 2,

                  color: 'text.secondary',
                }}
                hidden={isEdit}
              >
                {endlet.endletName}
              </Typography>
              <Button
                onClick={() => {
                  setIsEdit(true)
                }}
                variant="contained"
                hidden={contentLoading || isEdit}
              >
                Change
              </Button>
              <LoadingButton
                onMouseDown={(e) => {
                  e.preventDefault()
                  handleChangeElName(e)
                }}
                variant="contained"
                loading={loading}
                hidden={contentLoading || !isEdit}
              >
                Save
              </LoadingButton>
            </Box>
            <Typography
              component={'span'}
              sx={{
                m: 2,
                cursor: 'pointer',
                color: 'text.secondary',
              }}
              onClick={() => {
                navigate('/endlets')
              }}
            >
              <IconButton>
                <ArrowBackIcon />
              </IconButton>
              View all Endlets
            </Typography>
          </Stack>

          <>
            <Card sx={{ m: 3, p: 2 }}>
              <div
                className={endlet.status === 'Up' ? 'd-flex align-items-center' : ''}
              >
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{
                    mb: endlet.status === 'Up' ? 0 : 2,
                  }}
                >
                  <Typography variant="h6" mt={1} sx={{ m: 0 }}>
                    Status
                  </Typography>
                  <LoadingButton
                    variant="outlined"
                    onClick={() => handleValidateSSH()}
                    loading={isLoading}
                    hidden={
                      contentLoading ||
                      !endlet?.currentProgress?.filter(
                        (step: any) => step.issue.isResolved === false
                      )?.length ||
                      endlet.status === 'Up'
                    }
                  >
                    Retry Validation
                  </LoadingButton>
                </Stack>

                {contentLoading ? (
                  <Box sx={{ ml: 2, mr: 2 }}>
                    <Skeleton animation="wave" height={150} />
                  </Box>
                ) : (
                  <>
                    {endlet.status === 'Up' ? (
                      <>
                        <Label
                          variant={
                            theme.palette.mode === 'light' ? 'ghost' : 'filled'
                          }
                          color={'success'}
                          sx={{
                            textTransform: 'none',
                            m: 1,
                            width: '40px',
                            height: '30px',
                            fontSize: '15px',
                          }}
                        >
                          Up
                        </Label>
                      </>
                    ) : (
                      <>
                        <Stepper
                          activeStep={
                            currentState.deploymentStatus === 'Firewall Built'
                              ? 4
                              : osStages.includes(currentState.deploymentStatus)
                              ? currentState?.issue?.isResolved === false
                                ? 2
                                : 3
                              : currentState?.issue?.isResolved === false
                              ? STATUS_OPTIONS.indexOf(currentState.deploymentStatus)
                              : STATUS_OPTIONS.indexOf(
                                  currentState.deploymentStatus
                                ) + 1
                          }
                          alternativeLabel
                          sx={{ pb: 3 }}
                          className={c.root}
                        >
                          {STATUS_OPTIONS.map((label, index) => {
                            const labelProps: any = {}
                            let firewallBuiltStage: any = {}
                            if (label === 'Endlet Deployed') {
                              firewallBuiltStage = endlet?.currentProgress.find(
                                (data: any) =>
                                  data.deploymentStatus === 'Firewall Built'
                              )
                            }
                            const currentStage = endlet?.currentProgress.find(
                              (data: any) => data.deploymentStatus === label
                            )
                            if (
                              firewallBuiltStage?.issue?.isResolved === false ||
                              currentStage?.issue?.isResolved === false ||
                              (osStages.includes(label) && isErrorInOsStep)
                            ) {
                              labelProps.error = true
                            }

                            return (
                              <Step key={label}>
                                <Tooltip
                                  title={
                                    osStages.includes(label) && isErrorInOsStep
                                      ? endlet?.currentProgress.find(
                                          (step: any) =>
                                            osStages.includes(
                                              step.deploymentStatus
                                            ) && step.issue?.isResolved === false
                                        )?.issue?.message
                                      : firewallBuiltStage?.issue?.isResolved ===
                                        false
                                      ? firewallBuiltStage?.issue?.message
                                      : endlet?.currentProgress.filter(
                                          (step: any) =>
                                            ![
                                              'Interface Retrieved',
                                              'Secrets Validated',
                                            ].includes(step.deploymentStatus)
                                        )[index]?.issue?.message || ''
                                  }
                                >
                                  <StepLabel
                                    {...labelProps}
                                    sx={{
                                      textAlign: 'center',
                                    }}
                                  >
                                    {label === 'OS Validated'
                                      ? 'O/S Validated'
                                      : label}
                                  </StepLabel>
                                </Tooltip>
                              </Step>
                            )
                          })}
                        </Stepper>
                      </>
                    )}
                  </>
                )}
              </div>
            </Card>
            <Stack
              direction={'row'}
              justifyContent="flex-end"
              sx={{ width: '100%', pr: 3 }}
            >
              <LoadingButton
                loading={contentLoading}
                color="error"
                variant="outlined"
                sx={{ mb: 2 }}
                onClick={() => {
                  setOpenDeleteEP(true)
                }}
              >
                Delete Endlet
              </LoadingButton>
            </Stack>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
              // sx={{ px: 1, bgcolor: 'background.neutral' }}
              sx={{ ml: 3 }}
            >
              {TAB_OPTIONS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.label}
                  label={tab.label}
                  value={tab.value}
                  icon={tab.icon}
                  // onClick={() => navigate(`/endlets/${id}/${tab.value}`)}
                  onClick={() =>
                    navigate(PATH_SERVICES.endlets.endletDetails(id, tab.value))
                  }
                  // onClick={() => setCurrentTab(tab.label)}
                />
              ))}
            </Tabs>
          </>
          <Card sx={{ p: 2, m: 3 }}>
            <>
              {TAB_OPTIONS.map((tab) => {
                const isMatched = tab.value === currentTab
                return isMatched && !contentLoading ? (
                  <Box key={tab.value}>{tab.component}</Box>
                ) : isMatched && contentLoading ? (
                  <>
                    <Box sx={{ m: 2 }}>
                      <Skeleton animation="wave" height={60} />
                      <Skeleton animation="wave" height={60} />
                      <Skeleton animation="wave" height={60} />
                    </Box>
                  </>
                ) : (
                  ''
                )
              })}
            </>
          </Card>

          <DeleteEPModal
            openProps={{ open: openDeleteEP, setOpen: setOpenDeleteEP }}
            services={endlet?.services?.length ? true : false}
            id={endlet?.endletId}
          />
        </Page>
      </InterfacesContext.Provider>
    </EndletContext.Provider>
  )
}

export default EndletDetails

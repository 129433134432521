import React, { createContext, useContext, useEffect, useState } from 'react'
import { Box, Button, Typography, Skeleton, Chip } from '@mui/material'
import { Stack } from '@mui/system'
import { get, del, post } from 'src/utils/httpMethods'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChangevWANs from './ChangevWANs'
import NewServiceModal from './NewServiceModal'
import MeshConfiguration from './MeshConfiguration'
import PointToPointServices from './PointToPointServices'
import HubNSpokevWan from './HubNSpokevWan'
import DeleteServiceModal from './DeleteServiceModal'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import RemoveServiceModal from './RemoveServiceModal'
import { EndletContext } from './EndletDetails'
import SelectExistingService from './SelectExistingService'
import { useTheme } from '@mui/material/styles'
import { TopLevelmodalContext } from 'src/contexts/ModalContext'
import { LoadingButton } from '@mui/lab'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

export const ConfigureContext = createContext<any>(null!)

function EpDtServices() {
  const { endlet, endletId: id, getEndlets } = useContext(EndletContext)!
  const [openVWAN, setOpenvWAN] = useState(false)
  const [ipBlocksLeft, setIPBlocksLeft] = useState<any>([])
  const [newEPAdded, setNewEpAdded] = useState(false)
  const [openNewService, setOpenNewService] = useState(false)
  const [openDeleteService, setOpenDeleteService] = useState(false)
  const [openRemoveService, setOpenRemoveService] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [endletService, setEndletService] = useState<any>({})
  const theme = useTheme()
  const [arrayState, setArrayState] = useState<Array<boolean>>([])
  const [expanded, setExpanded] = useState<Array<boolean>>([true, false, false])
  // const servicesArr = endlet?.services?.length ? [...endlet.services] : ['']
  const [isIPAdded, setIsIPAdded] = useState(false)
  const [openSelectExistingServ, setOpenSelectExistingServ] = useState(false)
  const [delVWANType, setDelVWANType] = useState('')
  const { setModalProps } = useContext(TopLevelmodalContext)!
  const [loadingSync, setLoadingSync] = useState(false)

  useEffect(() => {
    if (newEPAdded) getEndlets()
  }, [newEPAdded])

  useEffect(() => {
    get(
      `${process.env.REACT_APP_HOST_API_URL}/endlets/services/details/${id}`,
      {}
    ).then((response: any) => {
      console.log(response)
      setEndletService(response)
      setExpanded(
        Array.from({ length: response?.services?.length }, (_, i) => i === 0)
      )
    })
  }, [])
  const handleChange = (index: any) => {
    const newArr = [...expanded]
    newArr[index] = !expanded[index]
    setExpanded(newArr)
  }

  const syncEndlets = () => {
    setLoadingSync(true)
    const filteredServices = endlet.services?.filter(
      (serv: any) => serv.type === 'vWAN'
    )
    const vWANService: any = filteredServices[0]
    const peers = []
    let endlets: any
    if (filteredServices.length) {
      endlets = vWANService.associatedEndlets.map((each: any) => {
        return {
          id: each.endletUniqueId,
        }
      })
      if (vWANService.subType === 'Point To Point') {
        const intermediateNode = vWANService.associatedEndlets.find(
          (el: any) => el.endletType === 'NOD'
        )

        const terminalEndlets = vWANService.associatedEndlets.filter(
          (el: any) => el.endletType !== 'NOD'
        )

        if (intermediateNode && Object.keys(intermediateNode)?.length) {
          peers.push(
            {
              side_a: terminalEndlets[0].endletUniqueId,
              side_b: intermediateNode.endletUniqueId,
            },
            {
              side_a: intermediateNode.endletUniqueId,
              side_b: terminalEndlets[1].endletUniqueId,
            }
          )
        } else {
          peers.push({
            side_a: terminalEndlets[0].endletUniqueId,
            side_b: terminalEndlets[1].endletUniqueId,
          })
        }
      } else {
        for (let i = 0; i < vWANService.associatedEndlets.length; i++) {
          for (let j = i + 1; j < vWANService.associatedEndlets.length; j++) {
            peers.push({
              side_a: vWANService.associatedEndlets[i].endletUniqueId,
              side_b: vWANService.associatedEndlets[j].endletUniqueId,
            })
          }
        }
      }
    }
    const payload = {
      id: vWANService.id,
      name: vWANService.name,
      organization_id: vWANService.organizationId,
      endlets,
      peers,
    }
    post(`${process.env.REACT_APP_HOST_API_URL}/endlets/sync`, payload)
      .then((response: any) => {
        setLoadingSync(false)
        console.log(response)
      })
      .catch((error: any) => {
        setLoadingSync(false)
        setModalProps({
          open: true,
          message: error.message,
          severity: 'error',
        })
      })
  }

  return (
    <ConfigureContext.Provider
      value={{
        isIpAddedProps: { isIPAdded, setIsIPAdded },
        ipBlockProps: ipBlocksLeft,
        setStateMethodProps: {
          setNewEpAdded,
        },
        isSuperUser: false,
      }}
    >
      <>
        <Stack
          sx={{ mt: 2, mb: 2 }}
          direction="row"
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            variant="outlined"
            sx={{ width: 'fit-content' }}
            onClick={() => setOpenSelectExistingServ(true)}
            hidden={endlet.services.length}
          >
            Select Existing Service
          </Button>
          <Button
            variant="outlined"
            sx={{ width: 'fit-content' }}
            onClick={() => setOpenNewService(true)}
          >
            + Add New Services
          </Button>
        </Stack>
        {endlet.serviceType.includes('vWAN') ? (
          <>
            {endletService.services?.map((each: any, index: number) => (
              <Accordion
                defaultExpanded={index === 0}
                expanded={expanded[index]}
                onChange={() => handleChange(index)}
                key={index}
                sx={{
                  borderTop: expanded[index]
                    ? `3px solid ${theme.palette.primary.main}`
                    : '',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="subtitle1">
                    {endlet.services.length
                      ? endlet?.serviceType[index] + ' - ' + each.subType
                      : endlet?.serviceType[0]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack
                    direction={'row'}
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Stack direction={'row'} spacing={2} alignItems="center">
                      <Typography variant="body1" sx={{ pb: 1, pt: 1 }}>
                        {/* Current vWan */}
                        vWAN Status
                      </Typography>
                    </Stack>

                    <Stack direction={'row'} spacing={1}>
                      <LoadingButton
                        hidden={
                          (each.subType === 'Point To Point' &&
                            each?.associatedEndlets?.length >= 2) ||
                          (each.subType !== 'Point To Point' &&
                            each?.associatedEndlets?.length >= 2)
                            ? false
                            : true
                        }
                        loading={loadingSync}
                        size="small"
                        sx={{ width: 'fit-content', textTransform: 'none' }}
                        variant="outlined"
                        onClick={() => {
                          syncEndlets()
                        }}
                      >
                        Sync
                      </LoadingButton>
                      <Button
                        size="small"
                        sx={{ width: 'fit-content', textTransform: 'none' }}
                        variant="outlined"
                        onClick={() => {
                          setOpenvWAN(true)
                          setCurrentIndex(index)
                        }}
                      >
                        Change vWAN
                      </Button>

                      <Button
                        hidden={endlet.services.length ? false : true}
                        size="small"
                        sx={{ width: 'fit-content', textTransform: 'none' }}
                        variant="outlined"
                        color="error"
                        onClick={() => (
                          setOpenRemoveService(true),
                          setCurrentIndex(index),
                          setDelVWANType('Remove')
                        )}
                      >
                        Remove vWAN From Endlet
                      </Button>
                      <Button
                        hidden={endlet.services.length ? false : true}
                        size="small"
                        sx={{ width: 'fit-content', textTransform: 'none' }}
                        variant="outlined"
                        color="error"
                        onClick={() => (
                          setOpenDeleteService(true),
                          setCurrentIndex(index),
                          setDelVWANType('Delete')
                        )}
                      >
                        Delete vWAN
                      </Button>
                    </Stack>
                  </Stack>
                  <LightTooltip
                    title={
                      each?.deploymentStatus === 'Service Configured' ||
                      each?.deploymentStatus === 'Service Deployed'
                        ? `${each.deploymentStatus} In Process`
                        : each?.deploymentStatus === 'Service Up' ||
                          each?.deploymentStatus === 'Service Down'
                        ? each.deploymentStatus
                        : ''
                    }
                    placement="right"
                  >
                    <Chip
                      label={
                        endlet.services?.length
                          ? endlet.services[index]?.name
                          : 'None'
                      }
                      sx={{
                        color: 'white',
                        p: 2,
                        fontWeight: 'bold',
                        backgroundColor:
                          each?.deploymentStatus === 'Service Configured' ||
                          each?.deploymentStatus === 'Service Deployed'
                            ? 'orange'
                            : each?.deploymentStatus === 'Service Up'
                            ? '#00A54F'
                            : each?.deploymentStatus === 'Service Down'
                            ? '#FF4842'
                            : '#0BB6DE',
                      }}
                    />
                  </LightTooltip>

                  {endlet.services.length ? (
                    each?.subType === 'Point To Point' ? (
                      <>
                        <PointToPointServices
                          isIpAddedProps={{ isIPAdded, setIsIPAdded }}
                          setStateMethodProps={{
                            setNewEpAdded,
                          }}
                          indexProps={{ each, index, endlet }}
                          isSuperUser={false}
                        />
                      </>
                    ) : each?.subType === 'Full Mesh' ? (
                      <>
                        <MeshConfiguration
                          isIpAddedProps={{ isIPAdded, setIsIPAdded }}
                          index={index}
                          isSuperUser={false}
                          endlet={endletService}
                        />
                      </>
                    ) : each?.subType === 'Hub And Spoke' ? (
                      <HubNSpokevWan
                        index={index}
                        endlet={endletService}
                        isIpAddedProps={{ isIPAdded, setIsIPAdded }}
                        isSuperUser={false}
                      />
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        ) : (
          ''
        )}
      </>

      <SelectExistingService
        openProps={{
          open: openSelectExistingServ,
          setOpen: setOpenSelectExistingServ,
        }}
        endlet={endlet}
        id={id}
        currentIndex={currentIndex}
      />
      <ChangevWANs
        openProps={{
          open: openVWAN,
          setOpen: setOpenvWAN,
        }}
        endlet={endletService}
        id={id}
        currentIndex={currentIndex}
      />

      <NewServiceModal
        openProps={{
          open: openNewService,
          setOpen: setOpenNewService,
        }}
      />
      <DeleteServiceModal
        openProps={{
          open: openDeleteService,
          setOpen: setOpenDeleteService,
        }}
        serviceId={endlet?.services[currentIndex]?.id!}
        endletId={endlet.endletId!}
        currentService={endletService?.services?.[currentIndex]}
      />

      <RemoveServiceModal
        openProps={{
          open: openRemoveService,
          setOpen: setOpenRemoveService,
        }}
        service={endletService?.services?.[currentIndex]!}
        endletId={endlet.endletId!}
        getDetails={getEndlets}
      />
    </ConfigureContext.Provider>
  )
}

export default EpDtServices

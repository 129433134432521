import React, { createContext, ReactNode, useEffect, useState } from 'react'

export interface SnackbarContextType {
  selectedTenant: string
  setSelectedTenant: React.Dispatch<React.SetStateAction<string>>
  selectedTenantData: any
  setSelectedTenantData: React.Dispatch<React.SetStateAction<any>>
}

export const TenantsContext = createContext<SnackbarContextType | null>(null)

function TenantsProvider({ children }: { children: ReactNode }) {
  const [selectedTenant, setSelectedTenant] = useState(
    localStorage.getItem('selecetedTenant') || ''
  )

  const [selectedTenantData, setSelectedTenantData] = useState(
    JSON.parse(localStorage.getItem('selectedTenantData') || '{}') //Tenant changes
  )

  useEffect(() => {
    localStorage.setItem('selecetedTenant', selectedTenant)
    localStorage.setItem('selectedTenantData', JSON.stringify(selectedTenantData)) //Tenant changes
  }, [selectedTenant])

  return (
    <TenantsContext.Provider
      value={{
        selectedTenant,
        setSelectedTenant,
        selectedTenantData,
        setSelectedTenantData,
      }}
    >
      {children}
    </TenantsContext.Provider>
  )
}

export default TenantsProvider
